import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slick from 'react-slick';

import { ReactComponent as ChevronRightIcon } from '../../svgs/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../svgs/icons/chevron-left.svg';
import GenericIcon from '../GenericIcon';

// The breakpoint till which we hide the arrows for collapsed layout
export const collapsedBp = 600;

export const ArrowButton = styled.button`
	position: absolute;
	margin: 0;
	padding: 0;
	font-size: 14px;
	top: 50%;
	transform: translateY(-50%);
	height: 32px;
	width: 32px;
	left: ${props =>
		props.on === 'right' ? 'auto' : props.collapsed ? '-6px' : '0'};
	right: ${props =>
		props.on === 'left' ? 'auto' : props.collapsed ? '-6px' : '0'};
	border-radius: 16px;
	background-color: #fff;
	position: absolute;
	z-index: 4;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	appearance: none;
	outline: none;
	cursor: pointer;
	transition: box-shadow 0.2s ease 0s;
	&:active {
		box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 1px,
			rgba(255, 255, 255, 0.7) 0px 0px 0px 5px !important;
	}
	&:focus:not(:active) {
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
	}

	&.slick-disabled {
		display: none;
	}

	.hostjane-slider-navicon {
		position: relative;
		&.hostjane-slider-navicon--prev {
			left: -1px;
		}
		&.hostjane-slider-navicon--next {
			right: -1px;
		}
	}
`;

function NextArrow({ className, onClick, collapsed }) {
	return (
		<ArrowButton
			collapsed={collapsed}
			onClick={onClick}
			className={className}
			on="right"
		>
			<GenericIcon
				title="Next"
				className="hostjane-slider-navicon hostjane-slider-navicon--next"
			>
				<ChevronRightIcon />
			</GenericIcon>
		</ArrowButton>
	);
}
function PrevArrow({ className, onClick, collapsed }) {
	return (
		<ArrowButton
			collapsed={collapsed}
			onClick={onClick}
			className={className}
			on="left"
		>
			<GenericIcon
				title="Previous"
				className="hostjane-slider-navicon hostjane-slider-navicon--prev"
			>
				<ChevronLeftIcon />
			</GenericIcon>
		</ArrowButton>
	);
}

export const SingleSlideContainer = styled.div`
	margin: 0;
	height: 100%;
	flex-shrink: 0;
	max-width: calc(100vw - 20px);
	min-width: 100px;
`;

const SlidersContainer = styled.div`
	opacity: ${props => (props.hide ? 0 : 1)};
	transition: opacity 200ms ease-out;

	${SingleSlideContainer} {
		padding: ${props => (props.collapsed ? '8px' : '0')}
			${props => (props.collapsed ? '10px' : '15px')};
		width: ${props => (props.collapsed ? 'calc(100vw - 70px)' : 'auto')};
		@media screen and (min-width: ${collapsedBp + 1}px) {
			width: auto;
		}
	}

	${ArrowButton} {
		display: ${props => (props.collapsed ? 'none' : 'flex')};
		@media screen and (min-width: ${collapsedBp + 1}px) {
			display: flex;
		}
		&.slick-disabled {
			display: none;
		}
	}

	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		user-select: none;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;

		display: block;
		overflow: hidden;

		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}
	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;

		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}

	/** equal height stuff */
	.slick-track,
	.slick-list {
		display: flex;
		align-items: stretch;
	}
	.slick-slide {
		height: 100%;
		flex-shrink: 0;
		> div {
			height: 100%;
		}
	}
	/** NOTE: The implementing div also needs height: 100% */
`;

export default function Slider({
	children,
	responsive = [
		{
			breakpoint: 50000,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1800,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
	collapsed = false,
}) {
	const [hide, setHide] = useState(true);
	useEffect(() => {
		const handler = () => {
			setHide(false);
		};
		if (typeof document !== 'undefined') {
			window.addEventListener('DOMContentLoaded', handler);
			if (
				document.readyState === 'complete' ||
				document.readyState === 'interactive'
			) {
				handler();
			}
		}
		return () => {
			if (typeof document !== 'undefined') {
				window.removeEventListener('DOMContentLoaded', handler);
			}
		};
	}, []);

	const settings = {
		dots: false,
		infinite: false,
		speed: 300,
		autoplay: false,
		variableWidth: collapsed,
		slidesToScroll: 1,
		slidesToShow: 1,
		responsive,
		nextArrow: <NextArrow collapsed={collapsed} />,
		prevArrow: <PrevArrow collapsed={collapsed} />,
	};
	return (
		<SlidersContainer hide={hide} collapsed={collapsed}>
			<Slick {...settings}>{children}</Slick>
		</SlidersContainer>
	);
}
