import React, { Fragment } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ReactComponent as Checked } from '../../svgs/icons/checked.svg';

const HostJaneSlideWrapper = styled.div`
	&.hostjane-main__HostJaneSlide {
		cursor: pointer;
		background-color: #a885ee;
		animation: backgroundChange 39s infinite;
		animation-delay: 3s;
		@keyframes backgroundChange {
			7.69% {
				background-color: #a885ee;
			}
			15.38% {
				background-color: #bfa1fb;
			}
			23.07% {
				background-color: #61bd4f;
			}
			30.76% {
				background-color: #8ab93c;
			}
			38.45% {
				background-color: #ffbd00;
			}
			46.14% {
				background-color: #ffae00;
			}
			53.83% {
				background-color: #33adff;
			}
			61.52% {
				background-color: #5b95fb;
			}
			69.21% {
				background-color: #0988b9;
			}
			76.9% {
				background-color: #07def0;
			}
			84.59% {
				background-color: #f38cda;
			}
			92.28% {
				background-color: #ee5dca;
			}
			96% {
				background-color: #3b99de;
			}
			100% {
				background-color: #3caede;
			}
		}
		height: 500px;
		margin: 0 auto;
		max-width: 880px;
		background-size: cover;
		background-position: center;
		border-radius: 1rem;
		box-shadow: 0 10px 20px rgba(74, 115, 159, 0.29);
		font-size: 1rem;
		overflow: hidden;
		padding: 18px;
		position: relative;
		text-align: left;
		.hostjane-main__HostJaneSlide__header {
			overflow: auto;
			padding-bottom: 1rem;
			.hostjane-main__HostJaneSlide__header__title {
				float: left;
				h3 {
					background: rgba(11, 86, 139, 0.25);
					border-radius: 6px;
					color: white;
					padding: 9px 15px;
					font-size: 1.25rem;
					letter-spacing: 1px;
					margin: 0;
					font-weight: 500;
					line-height: 1.2;
				}
			}
			.hostjane-main__HostJaneSlide__header__board {
				float: left;
				padding: 2px;
				margin-left: 15px;
				.user {
					float: left;
					padding: 2px;
					margin-left: 15px;
					&.user-1 {
						width: 38px;
						height: 38px;
						float: right;
						margin-left: -5px;
						background-image: url(${props => props.members[0]});
						border-radius: 50%;
					}
					&.user-2 {
						width: 38px;
						height: 38px;
						float: right;
						margin-left: -5px;
						background-image: url(${props => props.members[1]});
						border-radius: 50%;
					}
					user-3 {
					}
					svg {
					}
				}
			}
		}
		.hostjane-main__HostJaneSlide__lists {
			min-width: 1000px;
			overflow: auto;
			.list {
				display: block;
				float: left;
				margin: 0 10px 5px 0;
				width: 230px;
				&.to-do {
				}
				.list-content {
					.card {
						background-color: #ccdce6;
						border-radius: 4px;
						font-size: 0.9rem;
						margin-bottom: 8px;
						min-height: 33px;
						position: relative;
						overflow: hidden;
						border: none;
						&.card-empty {
						}
						&.in-motion {
							box-shadow: 0 2px 4px rgba(47, 73, 89, 0.09);
							left: 191px;
							overflow: visible;
							position: absolute;
							top: 123px;
							-webkit-transform: rotate(-10deg);
							transform: rotate(-10deg);
							width: 210px;
							z-index: 1;
							left: 481px;
							top: 113px;
							&:before {
								background: url(https://d2k1ftgv7pobq7.cloudfront.net/meta/p/res/pattern-library/images/d165943b7abc383fbc4d66f3cc2c6ff0/arrow.svg);
								content: '';
								height: 19px;
								left: -100px;
								position: absolute;
								top: 13px;
								width: 89px;
							}
						}
						.card-content {
							background-color: #fff;
							padding: 6px 8px;
							min-height: 33px;
						}
					}
					background-color: #e5eff5;
					border-radius: 6px;
					padding: 8px;
					&.to-do {
						background-color: transparent;
						border-radius: 0;
						padding: 0px;
					}
					h5 {
						font-weight: 600;
						font-size: 1rem;
						letter-spacing: 0.5px;
						margin: 0 0 8px 3px;
					}
				}
				.add-card {
					color: #aaa;
					font-size: 0.9rem;
					margin: 0 0 5px 5px;
				}
				.add-list {
					background: rgba(11, 86, 139, 0.25);
					border-radius: 6px;
					color: white;
					padding: 9px 15px;
				}
			}
		}
		.hostjane-main__HostJaneSlide__card-back-overlay {
			background-color: rgba(27, 46, 57, 0.6);
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			.card-back {
				background-color: #e5eff5;
				border-radius: 10px 10px 0 0;
				margin: 40px auto 0;
				max-width: 570px;
				padding: 20px;
				h5 {
					margin: 0 0 0.65rem;
					font-size: 1.5625rem;
					line-height: 1.2;
				}
				.meta {
					line-height: 1.5;
					overflow: auto;
					margin-bottom: 1rem;
					display: flex;
					align-items: center;
					.members {
						float: left;
						color: #172b4d;

						.user {
							width: 32px;
							height: 32px;
							&.user-2 {
								border-radius: 50%;
								background-image: url(${props => props.user2});
							}
							&.user-1 {
								border-radius: 50%;
								margin-left: 8px;
								background-image: url(${props => props.user1});
							}
							background-repeat: no-repeat;
							background-size: contain;
							float: left;
						}
					}
					.due-date {
						margin-left: 50px;
						svg {
							fill: white;
							float: left;
							height: 20px;
							margin: 2px 8px 0 0;
							width: 20px;
						}
						.due {
							background-color: #0069ff;
							border-radius: 4px;
							color: white;
							float: left;
							padding: 4px 8px;
						}
					}
				}

				h6 {
					font-weight: 600;
					margin: 0 0 0.25rem;
					font-size: 1.25rem;
					line-height: 1.2;
				}
				p {
					line-height: 1.5;
					margin-top: 0;
					margin-bottom: 1rem;
				}
				.checklist {
					font-style: italic;

					.progress-track {
						line-height: inherit;
						display: flex;
						align-items: center;
						span {
							font-size: 1rem;
							margin-right: 10px;
						}
						.progress-bar {
							margin-right: 0px;
							height: 10px;
							width: 312px;
							border-radius: 5px;
							background-color: #4caf50;
						}
					}
					ul {
						color: #6d8ea2;
						font-style: italic;
						list-style-type: none;
						padding: 10px 0 10px 25px;
						li {
							line-height: 1.5;
							position: relative;
							svg {
								fill: #6d8ea2;
								height: 14px;
								left: -24px;
								position: absolute;
								top: 5px;
								width: 14px;
							}
						}
					}
				}
				textarea {
					border: 0;
					border-radius: 10px;
					width: 100%;
					height: 80px;
					overflow: auto;
					resize: vertical;
				}
			}
		}
		.hostjane-main__HostJaneSlide___captions {
			background-color: rgba(48, 54, 76, 0.8);
			bottom: 0;
			color: white;
			font-weight: bold;
			left: 0;
			padding: 15px;
			position: absolute;
			text-align: center;
			width: 100%;
			.caption {
				font-weight: normal;
				font-size: 1.4rem;
				&.caption-1 {
					display: block;
				}
				&.caption-2 {
					display: none;
				}
				&.caption-3 {
					display: none;
				}
				&.caption-4 {
					display: none;
				}
				&.caption-5 {
					display: none;
				}
			}
			nav {
				display: flex;
				justify-content: center;
				margin: 10px auto 5px;
				div {
					cursor: pointer;
					display: block;
					float: left;
					height: 30px;
					overflow: hidden;
					position: relative;
					text-indent: 100%;
					white-space: nowrap;
					width: 30px;
					&.step-1,
					&.step-2,
					&.step-3,
					&.step-4,
					&.step-5 {
						&:after {
							background-color: #6d8ea2;
							border-radius: 50%;
							content: '';
							height: 10px;
							left: 50%;
							margin: -5px 0 0 -5px;
							position: absolute;
							top: 50%;
							transition: all 0.2s;
							width: 10px;
						}
						&:hover {
							&:after {
								background-color: #00c2e0;
							}
						}
					}

					&.active {
						display: block;
						float: left;
						height: 30px;
						overflow: hidden;
						position: relative;
						text-indent: 100%;
						white-space: nowrap;
						width: 30px;
						&:after {
							background-color: #00c2e0;
							height: 15px;
							margin: -7.5px 0 0 -7.5px;
							width: 15px;
							border-radius: 50%;
							content: '';
							left: 50%;
							position: absolute;
							top: 50%;
							transition: all 0.2s;
						}
					}
				}
			}
		}
	}
`;

const HostJaneSlide = ({
	activeIndex,
	setActiveIndex,
	cards,
	slideMainTitle,
	membersAvatars,
}) => {
	const handleActiveSlideIndex = () => {
		if (activeIndex !== 4) {
			const tempActiveIndex = activeIndex + 1;
			setActiveIndex(tempActiveIndex);
		} else {
			setActiveIndex(0);
		}
	};
	return (
		<HostJaneSlideWrapper
			className="hostjane-main__HostJaneSlide"
			user1={cards?.[activeIndex]?.modalData?.membersAvatars?.[0]}
			user2={cards?.[activeIndex]?.modalData?.membersAvatars?.[1]}
			members={membersAvatars}
			onClick={handleActiveSlideIndex}
		>
			<div className="hostjane-main__HostJaneSlide__header">
				<div className="hostjane-main__HostJaneSlide__header__title">
					<h3>{slideMainTitle}</h3>
				</div>
				<div className="hostjane-main__HostJaneSlide__header__board">
					{membersAvatars.map((__, index) => {
						return <div className={`user user-${index + 1}`} />;
					})}
				</div>
			</div>
			<div className="hostjane-main__HostJaneSlide__lists">
				{cards[activeIndex].cards.map(
					({ title, listItems, addCard, addList }) => {
						return (
							<div className="list ">
								<div
									className={`list-content ${
										addList && 'to-do'
									}`}
								>
									<h5>{title}</h5>
									{listItems?.length > 0 &&
										listItems.map(({ desc, inMotion }) => {
											return (
												<>
													{inMotion && (
														<div className="card empty" />
													)}
													<div
														className={classNames(
															'card',
															'empty',
															inMotion &&
																'in-motion'
														)}
													>
														<div className="card-content">
															{' '}
															{desc}{' '}
														</div>
													</div>
												</>
											);
										})}
									{addList && (
										<div className="add-list">
											Add a list…{' '}
										</div>
									)}
									{addCard && (
										<div className="add-card">
											Add a card…{' '}
										</div>
									)}
								</div>
							</div>
						);
					}
				)}
			</div>
			{cards[activeIndex].showModal && (
				<div className="hostjane-main__HostJaneSlide__card-back-overlay">
					<div className="card-back">
						<div>
							<h5> {cards[activeIndex].modalData.title}</h5>
							<div className="meta">
								<div className="members">
									<div>Members</div>
									<div className="user user-2" />
									<div className="user user-1" />
								</div>
								<div className="due-date">
									<div>Due Date</div>
									<span className="due">
										<Checked />
										{
											cards[activeIndex].modalData.dueDate
										}{' '}
									</span>
								</div>
							</div>
							<h6>Description</h6>
							<p>{cards[activeIndex].modalData.description}</p>
							<h6>Checklist</h6>
							<div className="checklist">
								<div className="progress-track">
									<span>100% Complete</span>
									<span className="progress-bar" />
								</div>
								<ul>
									{cards[activeIndex].modalData.checklist.map(
										listItem => {
											return (
												<li>
													<Checked />
													{listItem}
												</li>
											);
										}
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="hostjane-main__HostJaneSlide___captions">
				{[1, 2, 3, 4, 5].map((__, index) => {
					return (
						<div className={`caption caption-${index + 1}`}>
							{cards[activeIndex].bottomLabel}
						</div>
					);
				})}
				<nav>
					{[1, 2, 3, 4, 5].map((__, index) => {
						return (
							<div
								className={`step step-${index + 1} && ${
									activeIndex === index && 'active'
								}`}
								onClick={() => setActiveIndex(index)}
							/>
						);
					})}
				</nav>
			</div>
		</HostJaneSlideWrapper>
	);
};

export default HostJaneSlide;
