import React from 'react';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as CustomerSupport } from './customer-support.svg';
import { ReactComponent as Network } from './network.svg';
import { ReactComponent as ShieldStart } from './shield-star.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Cphosting } from './cpanel.svg';
import { ReactComponent as Woo } from './woo.svg';
import { ReactComponent as AWS } from './aws.svg';
import { ReactComponent as WordPress } from './managed-wordpress.svg';


const mapModalData = {
	titles: {
		3: { title: 'Asia Pacific', identifier: 'asia' },
		6: { title: 'Americas', identifier: 'us' },
		5: { title: 'EMEA', identifier: 'europe' },
		4: { title: 'Australia', identifier: 'oceania' },
	},
	description: `HostJane global network has 14 AWS datacenters for VPS, cloud compute, dedicated servers in prime locations.`,
	closeIcon: <Close />,
	parentServices: [
		{ icon: <AWS />, title: 'Your Amazon IP' },
		{ icon: <Cphosting />, title: 'Joomla VPS' },
		{ icon: <WordPress />, title: 'WordPress VPS' },
		{ icon: <Woo />, title: 'Drupal VPS' },
	],
	boxes: [
		{
			title: 'AWS Cloud',
			icon: <Network />,
			listItems: [
				'AWS datacenters',
				'SSD (Solid State Drives)',
			],
		},
		{
			title: '24/7 Support',
			icon: <CustomerSupport />,
			listItems: [
				'Linux admins',
				'Email or Telegram',
			],
		},
		{
			title: 'Unmetered bandwidth',
			icon: <Bookmark />,
			listItems: [
				'Server type / size options',
				'Scale any time',
			],
		},
		{
			title: 'AWS Security',
			icon: <ShieldStart />,
			listItems: [
				'Server monitoring',
				'Anti-DDoS defenses',
			],
		},
	],
};

export default mapModalData;
