import React, { lazy, Suspense, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import useMeasure from 'react-use-measure';
import { ReactComponent as RightArrows } from '../../../svgs/icons/right-arrows.svg';
import { useIsTablet } from '../../../utils/breakpoints';
import SiteContainer from '../../SiteContainer';
import { Heading, Wrapper, Description, StyledAnchor } from '../common';
import MapModal from '../MapModal';

const MapWrapper = styled(Wrapper)`
	background-color: #f2f4f4;
	position: relative;
	@media screen and (max-width: 767px) {
		background-color: rgb(30, 40, 50);
	}

	${Heading} {
		color: #000;
		margin-bottom: 15px;
		@media screen and (max-width: 767px) {
			color: #fff;
		}
	}

	${Description} {
		color: #000;
		margin-bottom: 0;
		@media screen and (max-width: 767px) {
			color: #fff;
			margin: 0px auto 20px;
		}
	}
`;

const MapLinks = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row wrap;
	display: none;
	@media screen and (max-width: 767px) {
		display: flex;
	}
`;

const Loader = styled.div`
	padding: 40px;
	display: flex;
	height: 200px;
	justify-content: center;
	align-items: center;
	background-color: rgb(30, 40, 50);
	color: #fff;
	margin-bottom: 30px;
`;

const MarkerDescription = styled.div`
	position: absolute;
	bottom: 100px;
	left: 10px;
	> div {
		display: flex;
		align-items: center;

		span {
			width: 11px;
			height: 11px;
			border-radius: 50%;
			margin-right: 15px;
			&.us-region {
				border: 3px solid #007dbc;
			}
			&.europe-region {
				border: 3px solid #eb5f07;
			}
			&.asia-region {
				border: 3px solid rgb(40, 167, 69);
			}
			&.oceania-region {
				border: 3px solid #a349a3;
			}
		}
		p {
			margin: 0;
			color: #222;
			font-size: 16px;
			line-height: 1.5;
			font-weight: 600;
		}
	}
`;

const LearnMore = styled.a`
	display: block;
	color: #007eb9;
	cursor: pointer;
	width: 100%;
	text-align: center;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.6;
	text-decoration: none;
	position: relative;
	svg {
		fill: #007eb9;
		width: 15px;
		height: 10px;
		stroke: rgb(0, 126, 185);
		stroke-width: 25px;
	}
	&:hover {
		text-decoration: none;
		color: #e47911;
		svg {
			outline: 0;
			fill: #e47911;
			stroke: rgb(228, 121, 17);
			stroke-width: 25px;
		}
		span {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 2px;
				bottom: 1px;
				border-bottom: 1px solid rgb(228, 121, 17);
			}
		}
	}
`;

const BottomSection = styled.div`
	position: relative;
	max-width: 1100px;
	margin: 0 auto;
	@media screen and (max-width: 767px) {
		display: none;
	}
`;
const MapsSection = lazy(() => import('./MapsSection.js'));

export default function Map({ data, id }) {
	const isTablet = useIsTablet();
	const [modal, setModal] = useState(false);
	const [titleNumber, setTitleNumber] = useState(1);

	const [ref, bounds] = useMeasure({
		debounce: 0,
		polyfill: ResizeObserver,
		scroll: true,
	});

	const handleModalDisplay = (value, tNumber) => {
		setTitleNumber(tNumber);
		setModal(value);
	};
	return (
		<MapWrapper id={id} ref={ref}>
			<SiteContainer
				onClick={() => {
					handleModalDisplay(false);
				}}
			>
				<MapModal
					modal={modal}
					handleModalDisplay={handleModalDisplay}
					titleNumber={titleNumber}
				/>
				<Heading>{data.title}</Heading>
				<Description>{data.description}</Description>
				{isTablet ? (
					<Suspense
						fallback={<Loader>Updating server data...</Loader>}
					>
						<MapsSection
							handleModalDisplay={handleModalDisplay}
							data={data}
							bounds={bounds}
						/>
					</Suspense>
				) : null}
				<MapLinks>
					{data.buttons.map((button, index) => (
						<StyledAnchor
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							href={button.link}
							className={classNames(
								'hostjane-button',
								`hostjane-button--${button.type || 'secondary'}`
							)}
						>
							{button.label}
						</StyledAnchor>
					))}
				</MapLinks>
				<BottomSection>
					<LearnMore href="https://cloud.hostjane.com)">
						<span>
							Get started from $17.95/mo{' '}
							<i>
								{' '}
								<RightArrows />{' '}
							</i>{' '}
						</span>
					</LearnMore>
					<MarkerDescription>
						<div>
							<span className="us-region" />
							<p> The Americas </p>
						</div>
						<div>
							<span className="europe-region" />
							<p> Europe / Middle East / Africa </p>
						</div>
						<div>
							<span className="asia-region" />
							<p> Asia Pacific </p>
						</div>
						<div>
							<span className="oceania-region" />
							<p> Australia </p>
						</div>
					</MarkerDescription>
				</BottomSection>
			</SiteContainer>
		</MapWrapper>
	);
}
