import React from 'react';
import { ReactComponent as FreelancersIcon } from './freelancers.svg';
import { ReactComponent as WebHostingIcon } from './web-hosting.svg';

export default {
	title: 'We make you money.',
	leftColumn: {
		title: 'Hire',
		icon: FreelancersIcon,
		description: 'Ready to work freelancers',
		ctas: [
			{
				type: 'primary',
				label: 'Find people',
				link: '/marketplace',
				internal: false,
			},
			{
				type: 'secondary',
				label: 'Become a Seller',
				link: 'https://www.hostjane.com/sell',
				internal: false,
			},
		],
	},
	rightColumn: {
		title: 'Hosting',
		icon: WebHostingIcon,
		description: 'Amazon VPS and cloud computes',
		ctas: [
			{
				type: 'primary',
				label: 'Buy now',
				link: 'https://cloud.hostjane.com',
				internal: false,
			},
			{
				type: 'secondary',
				label: 'Customer Portal',
				link: 'https://cloud.hostjane.com/hosting/login',
				internal: true,
			},
		],
	},
};
