import React from 'react';

export const title = 'Big and small projects';

export const explore = {
	url: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice', // give 'null' value to URL to remove button
	label: 'Design skills',
	internal: false,
};

export function Description() {
	return <>Only pay for what you need with no commitments.</>;
}
