import React from 'react';
import styled from 'styled-components';
import mapModalData from '../../../../site-data/home/map-modal';

const MapModalWrapper = styled.section`
	&.hostjane-main__MapModal {
		.hostjane-main__MapModal__main {
			position: absolute;
			width: 550px;
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3),
				0 0 0 1px rgba(0, 0, 0, 0.04);
			background-color: #fff;
			border: 1px solid #d5dbdb;
			padding: 15px 15px;
			z-index: 1;

			display: none;
			@media screen and (min-width: 768px) {
				${props => (props.modal ? `display: block` : `display: none;`)};
			}

			.hostjane-main__MapModal__main__close-icon {
				position: absolute;
				top: 10px;
				right: 15px;
				font-size: 18px;
				cursor: pointer;
				svg {
					width: 15px;
					height: 15px;
				}
			}
			.hostjane-main__MapModal__main__title {
				display: block;
				font-size: 1.8em;
				margin-block-start: 0.83em;
				margin-block-end: 0.83em;
				margin-inline-start: 0px;
				margin-inline-end: 0px;
				font-weight: bold;
				padding-bottom: 10px;
				border-bottom: 1px solid #f2f4f4;
				margin: 10px 0;
				margin-left: 8px;
				span {
					font-size: 17px;
					font-weight: normal;
					padding: 4px 12px;
					margin-left: 15px;

					&.asia {
						background-color: #e2ffdd;
						border-left: 8px solid #52d53a;
					}
					&.us {
						background-color: #edf4ff;
						border-left: 8px solid #0069ff;
					}
					&.europe {
						background-color: #fff4e1;
						border-left: 8px solid #ffa40d;
					}
					&.oceania {
						background-color: #f5edff;
						border-left: 8px solid #7a00ff;
					}
				}
			}
			.hostjane-main__MapModal__main__description {
				padding-bottom: 10px;
				border-bottom: 1px solid #f2f4f4;
				margin: 10px 0;
			}
			.hostjane-main__MapModal__main__parent-services {
				display: grid;
				grid-template-columns: 50% 45%;
				justify-content: flex-start;
				grid-column-gap: 10px;
				grid-row-gap: 20px;
				padding-bottom: 10px;
				border-bottom: 1px solid #f2f4f4;
				margin: 10px 0;
				.hostjane-main__MapModal__main__parent-services__service {
					display: flex;
					align-items: center;
					span {
						display: inherit;
						svg {
							width: 40px;
							height: 40px;
							margin-right: 10px;
						}
					}
					p {
						font-size: 20px;
						font-weight: 600;
						color: #414f7b;
						line-height: 1;
						margin: 0;
					}
				}
			}
			.hostjane-main__MapModal__main__services {
				display: grid;
				grid-template-columns: 50% 40%;
				justify-content: space-around;
				grid-column-gap: 10px;
				grid-row-gap: 20px;
				margin-top: 20px;
				margin-bottom: 20px;
				.hostjane-main__MapModal__main__services__service {
					.hostjane-main__MapModal__main__services__service__icon-bar {
						display: flex;
						align-items: center;
						margin-bottom: 13px;

						span {
							line-height: 0;
							svg {
								margin-right: 10px;
								margin-top: 0px;
								width: 20px;
								height: 20px;
							}
						}
						p {
							font-weight: 700;
							font-size: 20px;
							color: #414f7b;
							line-height: 1;
							margin: 0;
						}
					}
					ul {
						margin-bottom: 0;
						padding-left: 20px;
						li {
							position: relative;
							line-height: 30px;

							&:before {
								content: ' ';
								position: absolute;
								width: 7px;
								height: 7px;
								background-image: linear-gradient(
									89deg,
									#ff7854,
									#ffaf48
								);
								margin-top: 12px;
								border-radius: 50px;
								left: -20px;
							}
							list-style: none;
						}
					}
				}
			}
		}
	}
`;
const MapModal = ({ modal, handleModalDisplay, titleNumber }) => {
	return (
		<MapModalWrapper
			onClick={e => e.stopPropagation()}
			modal={modal}
			className="hostjane-main__MapModal"
		>
			<div className="hostjane-main__MapModal__main">
				<span
					onClick={e => {
						e.stopPropagation();
						handleModalDisplay(false);
					}}
					className="hostjane-main__MapModal__main__close-icon"
				>
					{mapModalData.closeIcon}
				</span>
				<h2 className="hostjane-main__MapModal__main__title">
					{mapModalData.titles[`${titleNumber}`]?.title}{' '}
					<span
						className={
							mapModalData.titles[`${titleNumber}`]?.identifier
						}
					>
						No account required
					</span>
				</h2>
				{/* <p className="hostjane-main__MapModal__main__description">
					{mapModalData.description}
				</p> */}
				<div className="hostjane-main__MapModal__main__parent-services">
					{mapModalData.parentServices.map(({ icon, title }) => {
						return (
							<div className="hostjane-main__MapModal__main__parent-services__service">
								<span>{icon}</span>
								<p>{title}</p>
							</div>
						);
					})}
				</div>
				<div>
					<div className="hostjane-main__MapModal__main__services">
						{mapModalData.boxes.map(
							({ title, icon, listItems }) => {
								return (
									<div className="hostjane-main__MapModal__main__services__service">
										<div className="hostjane-main__MapModal__main__services__service__icon-bar">
											<span>{icon}</span>
											<p>{title}</p>
										</div>
										<ul>
											{listItems.map(item => {
												return <li>{item}</li>;
											})}
										</ul>
									</div>
								);
							}
						)}
					</div>
				</div>
			</div>
		</MapModalWrapper>
	);
};

export default MapModal;
