import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import DismissableBanner from '../components/DismissableBanner';
import MainNavigation from '../components/MainNavigation';
import Hero from '../components/Home/Hero';
import HowItWorks from '../components/Home/HowItWorks';
import Map from '../components/Home/Map';
import Testimonial from '../components/Home/Testimonial';
import ServiceSlider from '../components/Home/ServiceSlider';
import Explore from '../components/Home/Explore';
import Features from '../components/Home/Features';
import MainFooter from '../components/MainFooter';
import FooterCTA from '../components/Home/FooterCTA';

import { pageDescription, pageTitle } from '../../site-data/home/seo';
import {
	Alert,
	backgroundColor,
	textColor,
	backgroundGradient,
} from '../../site-data/home/alert';
import homeHeroData from '../../site-data/home/hero';
import mapData from '../../site-data/home/map';
import howItWorksData from '../../site-data/home/howitworks';
import testimonialData from '../../site-data/testimonials';
import {
	title as webMobileSwTitle,
	Description as WebMobileSwDescription,
	explore as webMobileSwExplore,
} from '../../site-data/home/web-mobile-sw-slider';
import {
	title as designArtVideoTitle,
	Description as designArtVideoDescription,
	explore as designArtVideoExplore,
} from '../../site-data/home/design-art-video-slider';
import exploreData from '../../site-data/home/explore-solutions';
import featuresData from '../../site-data/home/features';
import footerCtaData from '../../site-data/home/footer-cta';
import Campaigns from '../components/Community/Campaigns';
import homeCampaignsData from '../../site-data/home/home-campaigns';
import TabletNavigation from '../components/Home/TabletNavigation';
import HostjaneSellers from '../components/Home/HostjaneSellers';
import WhatIsHostjane from '../components/Home/WhatIsHostjane';
import whatIsHostjaneData from '../../site-data/home/what-is-hostjane';
import everydayFindsData from '../../site-data/home/everyday-finds';
import HostJaneSlider from '../components/HostJaneSlider';
import hostjaneSliderData from '../../site-data/hostjane-slider';
import ScrollIndicator from '../components/ScrollIndicator';
import sectionIds from '../../site-data/home/section-ids';
import HostingNavButtons from '../components/HostingNavButtons';

function HomePage({ data, ...props }) {
	return (
		<Layout {...props} hasFooter={false} fullWidth>
			<ScrollIndicator sectionIds={sectionIds} />
			<SEO
				title={pageTitle}
				description={pageDescription}
				addPrefix={false}
			/>
			<DismissableBanner
				backgroundColor={backgroundColor}
				textColor={textColor}
				backgroundGradient={backgroundGradient}
			>
				{Alert === null ? null : <Alert />}
			</DismissableBanner>
			<MainNavigation landingPage />
			<TabletNavigation />
			<HostingNavButtons />

			<Hero data={homeHeroData} id={sectionIds.Hosting} />

			<HostjaneSellers
				id={sectionIds.Freelancers}
				title={everydayFindsData.title}
				subtitle={everydayFindsData.subtitle}
				shoppingItems={everydayFindsData.shoppingItems}
				rightArrow={everydayFindsData.rightArrow}
				titleColor={everydayFindsData.titleColor}
				authButtons={everydayFindsData.authButtons}
			/>
			<HostJaneSlider
				id={sectionIds.Workspace}
				title={hostjaneSliderData.title}
				subtitle={hostjaneSliderData.subtitle}
				cards={hostjaneSliderData.cards}
				slideMainTitle={hostjaneSliderData.slideMainTitle}
				membersAvatars={hostjaneSliderData.membersAvatars}
			/>
			<HowItWorks data={howItWorksData} id={sectionIds['How it works']} />

			<WhatIsHostjane
				id={sectionIds.Mission}
				title={whatIsHostjaneData?.title}
				subtitle={whatIsHostjaneData.subtitle}
				backgroundImages={whatIsHostjaneData.backgroundImages}
				bottomText={whatIsHostjaneData.bottomText}
				bottomBtnText={whatIsHostjaneData.bottomBtnText}
				bottomBtnLink={whatIsHostjaneData.bottomBtnLink}
				cards={whatIsHostjaneData.cards}
			/>
			
			<ServiceSlider
				id={sectionIds.Creatives}
				slides={data.designArtVideo.edges}
				title={designArtVideoTitle}
				Description={designArtVideoDescription}
				explore={designArtVideoExplore}
			/>
			
			<Features data={featuresData} id={sectionIds.Innovation} />
			<FooterCTA data={footerCtaData} />
			<MainFooter />
		</Layout>
	);
}

export default HomePage;

export const pageQuery = graphql`
	query Services {
		webMobileSw: allMdx(
			sort: { fields: frontmatter___order, order: ASC }
			filter: {
				fileAbsolutePath: {
					glob: "**/web-mobile-sw-slider/contents/**"
				}
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						link
						image {
							childImageSharp {
								fluid(
									maxHeight: 320
									maxWidth: 320
									quality: 100
								) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
						isNew
						newBadge
					}
					body
				}
			}
		}
		designArtVideo: allMdx(
			sort: { fields: frontmatter___order, order: ASC }
			filter: {
				fileAbsolutePath: {
					glob: "**/design-art-video-slider/contents/**"
				}
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						link
						image {
							childImageSharp {
								fluid(
									maxHeight: 320
									maxWidth: 320
									quality: 100
								) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
						isNew
						newBadge
					}
					body
				}
			}
		}
	}
`;
