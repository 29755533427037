import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Wrapper } from '../common';
import SiteContainer from '../../SiteContainer';

const CardsWrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	align-content: center;
	justify-content: center;

	@media screen and (min-width: 1350px) {
		max-width: 1200px;
	}
`;

const CardContainer = styled.div`
	margin: 10px;
	width: 275px;
	flex: 0 1 275px;

	.hostjane-features-card__anchor {
		border: 1px solid #d5dbdb;
		display: block;
		padding: 15px;
		text-decoration: none;
		color: #333;
		height: 100%;
		line-height: 1.6;
	}
	.hostjane-features-card__icon {
		height: 180px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			height: 180px;
			width: auto;
		}
	}
	.hostjane-features-card__title {
		margin: 15px 0 0 0;
		font-size: 18px;
		text-align: center;
		font-weight: 700;
	}
	.hostjane-features-card__description {
		font-size: 13px;
		margin: 0;
	}
`;

function Card({ data }) {
	const inner = (
		<>
			<div className="hostjane-features-card__icon">
				<data.icon />
			</div>
			<h3 className="hostjane-features-card__title">{data.title}</h3>
			{/* <p className="hostjane-features-card__description">
				{data.description}
			</p> */}
		</>
	);
	return (
		<CardContainer className="hostjane-features-card">
			{data.internal ? (
				<Link className="hostjane-features-card__anchor" to={data.link}>
					{inner}
				</Link>
			) : (
				<a className="hostjane-features-card__anchor" href={data.link}>
					{inner}
				</a>
			)}
		</CardContainer>
	);
}

export default function Features({ data, id }) {
	return (
		<Wrapper id={id}>
			<SiteContainer>
				<CardsWrapper>
					{data.items.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Card data={item} key={index} />
					))}
				</CardsWrapper>
			</SiteContainer>
		</Wrapper>
	);
}
