import React from 'react';
import styled from 'styled-components';
import CampaignCard from '../CampaignCard';

const CampaignsWrapper = styled.section`
	overflow: hidden;
	padding: 50px 20px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 0;
	background: unset;
	> div {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
		justify-content: center;
		grid-column-gap: 15px;
		grid-row-gap: 20px;
		max-width: 1550px;
		margin: 0 auto;
		@media screen and (max-width: 1192px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 850px) {
			grid-template-columns: 1fr;
		}
	}

	${props =>
		props.homePage &&
		`display: none;
		padding: 20px 20px;
		@media screen and (max-width: 850px) {
			display: block;
		} `}
`;

const Campaigns = ({ campaigns, homePage, id }) => {
	return (
		<CampaignsWrapper id={id} homePage={homePage}>
			<div>
				{campaigns.map(
					({ campaignThumbnail, title, btnText, btnLink }, index) => {
						return (
							<CampaignCard
								index={index}
								title={title}
								imageSrc={campaignThumbnail}
								btnText={btnText}
								btnLink={btnLink}
							/>
						);
					}
				)}
			</div>
		</CampaignsWrapper>
	);
};

export default Campaigns;
