import { ReactComponent as JaneCloudIcon } from '../../../src/svgs/nav/JaneCloud.svg';
import { ReactComponent as JaneDediIcon } from '../../../src/svgs/nav/JaneDedi.svg';
import { ReactComponent as JaneSellsIcon } from '../../../src/svgs/nav/JaneSells.svg';
import { ReactComponent as JaneVPSIcon } from '../../../src/svgs/nav/JaneVPS.svg';

export default {
	title: 'Wizards to help find talent',
	items: [
		{
			icon: JaneVPSIcon,
			title: 'Developers',
			description:
				'Find freelance developers for web, mobile and apps at great prices.',
			list: [
				'Popular frameworks',
				'Expert WordPress coders',
				'Top 5% of talent',
			],
			link: '/community/websites/',
			linkLabel: 'Find Developers',
			internal: true,
		},
		{
			icon: JaneCloudIcon,
			title: 'Designers',
			description:
				'From logo design to mobile app wireframes, find graphic designers and Adobe experts.',
				list: [
					'Top design software',
					'Affordable rates',
					'Vetted portfolios',
				],
			link: '/community/designers/',
			linkLabel: 'Find Designers',
			internal: true,
		},
		{
			icon: JaneSellsIcon,
			title: 'Translators',
			description:
				'Native human translators across 30 popular language pairs.',
				list: [
					'Bilingual speakers',
					'Low dollar per word',
					'Vetted track records',
				],
			link: '/community/translators/',
			linkLabel: 'Find Translators',
			internal: true,
		},
		{
			icon: JaneDediIcon,
			title: 'Business',
			description:
				'Verified marketing, bookkeeping + legal services for small biz.',
			list: [
				'College grads to experts',
				'Affordable rates',
				'White hat methods',
			],
			link: '/community/business/',
			linkLabel: 'Find Marketers',
			internal: true,
		},
	],
};
