const homeCampaignsData = [
	{
		campaignThumbnail: require('./marketplace.png'),
		title: 'Hire people with top skills',
		btnText: 'Continue',
		btnLink: 'https://www.hostjane.com/marketplace',
	},
	{
		campaignThumbnail: require('./hosting.png'),
		title: 'Fully-managed web hosting',
		btnText: 'Continue',
		btnLink: 'https://cloud.hostjane.com',
	},
];

export default homeCampaignsData;
