import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
	&.hostjane-main-CampaignCard {
		width: 100%;
		position: relative;
		min-width: 343px;
		max-width: 500px;

		&:hover {
			a {
				.hostjane-main-CampaignCard__campaign-tile {
					background-color: rgba(230, 50, 65, 0.7);
					button {
						background-color: rgba(230, 50, 65, 0.7);
					}
				}
			}
		}
		a {
			color: rgb(75, 75, 75);
			background: transparent;
			text-decoration: none;
			img {
				display: block;
				max-width: 100%;
				border-width: 0px;
				border-style: initial;
				border-color: initial;
				border-image: initial;
			}
			.hostjane-main-CampaignCard__campaign-tile {
				position: absolute;
				bottom: 0;
				width: 100%;
				background: rgba(0, 51, 80, 0.7);
				transition: all 0.5s ease;
				min-height: 54px;

				h3 {
					width: unset;
					display: inline-block;
					color: #fff;
					padding: 0.5em 140px 0.5em 0.5em;
					position: relative;
					font-size: 18px;
					font-weight: normal;
					background: none;
					border-radius: 0;
					box-shadow: none;
					line-height: 130%;
					margin: 0px;
					padding: 0.5em 140px 1em 1em;
					font-size: 25px;
					font-weight: 00;
				}
				button {
					border: none;
					background-color: #003350;
					margin: 0;
					width: 125px;
					height: 100%;
					position: absolute;
					right: 0;
					bottom: 0;
					text-transform: uppercase;
					letter-spacing: 0px;
					color: #fff;
					font-size: 13px;
					transition: all 0.5s ease;
					padding-top: 5px;
					-webkit-writing-mode: horizontal-tb !important;
					text-rendering: auto;
					word-spacing: normal;
					display: inline-block;
					text-align: center;
					align-items: flex-start;
					text-indent: 0px;
					box-sizing: border-box;
					font-size: 17px;
				}
			}
		}
	}
`;
const CampaignCard = ({ imageSrc, title, btnText, btnLink, index }) => {
	return (
		<Card className="hostjane-main-CampaignCard" key={index}>
			<a href={btnLink}>
				<img src={imageSrc} alt="" />
				<div className="hostjane-main-CampaignCard__campaign-tile">
					<h3>{title}</h3>
					<button type="button">{btnText}</button>
				</div>
			</a>
		</Card>
	);
};

export default CampaignCard;
