export default {
	title: 'Take the next step',
	sections: [
		{
			title: 'Work with a trusted seller',
			buttons: [
				{
					title: 'Search talent',
					internal: false,
					link: '/marketplace',
					type: 'primary',
				},
				{
					title: 'Sign in',
					internal: false,
					link: '/marketplace/login',
					type: 'secondary',
				},
			],
		},
		{
			title: 'Cloud & VPS Hosting',
			buttons: [
				{
					title: 'View Services',
					internal: true,
					link: 'https://cloud.hostjane.com',
					type: 'primary',
				},
				{
					title: 'Sign in',
					internal: false,
					link: 'https://cloud.hostjane.com/hosting/login',
					type: 'secondary',
				},
			],
		},
	],
	list: [
		{
			label: 'Get free quotes',
			title: 'Talk to freelancers',
			link:
				'https://www.hostjane.com/marketplace/categories',
			internal: false,
		},
		{
			label: 'Create an income stream',
			title: 'Become a seller',
			link: 'https://www.hostjane.com/sell',
			internal: true,
		},
		{
			label: 'Continue browsing',
			title: 'See all solutions',
			link: 'https://www.hostjane.com/marketplace/skills',
			internal: false,
		},
	],
	footer:
		'HostJane serves as a limited authorized payment collection agent for Sellers.',
};
