import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Slider, { SingleSlideContainer } from '../../Slider';
import { ReactComponent as ExternalLinkIcon } from '../../../svgs/icons/external-link.svg';
import { ReactComponent as SignatureIcon } from '../../../svgs/icons/signature.svg';
import GenericIcon from '../../GenericIcon';
import { Heading, Wrapper, Description } from '../common';
import SiteContainer from '../../SiteContainer';

const SingleTestimonialContainer = styled(SingleSlideContainer)`
	color: #4a5669;
	display: flex;
	flex-flow: column nowrap;

	.hostjane-testimonial__body {
		padding: 30px;
		font-size: 18px;
		line-height: 33px;
		background-color: #fff;
		border-radius: 12px;
		flex: 1 0 auto;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: 30px;
			bottom: -10px;
			width: 20px;
			height: 20px;
			background-color: #fff;
			transform: rotate(45deg);
		}
	}

	.hostjane-testimonial__image {
		margin-bottom: 20px;
	}
	.hostjane-testimonial__signature {
		color: #0057d8;
		text-decoration: none;
		font-size: 18px;
		line-height: 1.25;
		display: block;
		padding: 0 0 0 26px;
		position: relative;
		margin: 0;
		transition: color 0.2s, background-color 0.2s;

		&:hover {
			text-decoration: underline;
		}
	}
	.hostjane-testimonial__signatureicon {
		position: absolute;
		left: 0;
		top: 0;
	}
	.hostjane-testimonial__title {
		font-size: 18px;
		font-weight: 500;
		margin: 8px 0 5px;
	}
	.hostjane-testimonial__content {
		margin: 0;
		p {
			margin: 0 0 10px 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.hostjane-testimonial__author {
		overflow: hidden;
		padding: 30px 30px 0 30px;
		margin: 0;
		flex: 0 0 auto;
	}
	.hostjane-testimonial__authorlink {
		display: inline-flex;
		align-items: center;
		color: #fff;
		text-decoration: none;
		font-size: 18px;
		margin: 0;
		transition: color 0.2s, background-color 0.2s;
		font-weight: 400;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}
	.hostjane-testimonial__authorlinktext {
		border-bottom: 1px dashed #fff;
	}
	.hostjane-testimonial__authorlinkicon {
		color: #fff;
		margin-left: 4px;
	}
	.hostjane-testimonial__authorname {
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		margin: 14px 0 0;
	}
`;
function SingleTestimonial({ fm, body, className, minHeight }) {
	return (
		<SingleTestimonialContainer className={className} minHeight={minHeight}>
			<div className="hostjane-testimonial__body">
				<Img
					className="hostjane-testimonial__image"
					fluid={fm.image.childImageSharp.fluid}
					alt={fm.businessName}
					fadeIn
					backgroundColor="#e3e3e3"
				/>
				<a href={fm.link} className="hostjane-testimonial__signature">
					<GenericIcon className="hostjane-testimonial__signatureicon">
						<SignatureIcon />
					</GenericIcon>
					<span className="hostjane-testimonial__signaturename">
						{fm.signatureName}
					</span>
				</a>
				<h4 className="hostjane-testimonial__title">{fm.title}</h4>
				<div className="hostjane-testimonial__content">
					<MDXRenderer>{body}</MDXRenderer>
				</div>
			</div>
			<div className="hostjane-testimonial__author">
				<a href={fm.link} className="hostjane-testimonial__authorlink">
					<span className="hostjane-testimonial__authorlinktext">
						View service
					</span>
					<GenericIcon className="hostjane-testimonial__authorlinkicon">
						<ExternalLinkIcon />
					</GenericIcon>
				</a>
				<p className="hostjane-testimonial__authorname">
					{fm.businessName}
				</p>
			</div>
		</SingleTestimonialContainer>
	);
}

const TestimonialWrapper = styled(Wrapper)`
	background-color: #009cde;
	background-image: radial-gradient(
		circle farthest-side at center bottom,
		#009cde,
		#003087 125%
	);
	color: #fff;

	${Heading} {
		color: #fff;
		margin-bottom: 15px;
	}

	${Description} {
		color: #fff;
	}
`;

export default function Testimonial({ data, id }) {
	const testimonials = useStaticQuery(graphql`
		query AllTestimonails {
			allTestimonials: allMdx(
				sort: { fields: frontmatter___order, order: ASC }
				filter: { fileAbsolutePath: { glob: "**/testimonials/**" } }
			) {
				edges {
					node {
						id
						body
						frontmatter {
							businessName
							signatureName
							link
							title
							image {
								childImageSharp {
									fluid(maxHeight: 300, maxWidth: 500) {
										...GatsbyImageSharpFluid_withWebp_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<TestimonialWrapper id={id}>
			<SiteContainer>
				<Heading>{data.title}</Heading>
				<Description>{data.description}</Description>
				<Slider>
					{testimonials.allTestimonials.edges.map(({ node }) => (
						<SingleTestimonial
							fm={node.frontmatter}
							key={node.id}
							body={node.body}
							className="hostjane-single-testimonial"
						/>
					))}
				</Slider>
			</SiteContainer>
		</TestimonialWrapper>
	);
}
