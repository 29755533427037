import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import GenericIcon from '../GenericIcon';
import { ReactComponent as TimesIcon } from '../../svgs/icons/times.svg';
import { useOpenGlobalModal } from '../../utils/globalModal';

const BannerContent = styled.div`
	&.hostjane-main__bannerContent {
		word-break: keep-all;
		white-space: pre;
		font-size: 15px;
		line-height: 42px;
		height: 42px;
		width: 100%;
		overflow-x: auto;
		padding: 0 ${props => props.theme.gutter.small}px;
		flex: 0 0 calc(100% - 37px);
	}
`;

const BannerHideButton = styled.button`
	&.hostjane-main__bannerHideButton {
		height: 32px;
		width: 32px;
		flex: 0 0 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 0;
		border: 0 none;
		outline: none;
		background: transparent;
		opacity: 1;
		cursor: pointer;
		will-change: opacity;
		transition: opacity 200ms ease-out;

		&:hover {
			opacity: 0.8;
		}
	}
`;

const BannerContainer = styled.div`
	&.hostjane-main__bannerContainer {
		display: flex;
		flex-flow: row nowrap;
		height: 42px;
		align-items: center;
		max-width: ${props => props.theme.siteApp.maxWidth}px;
		margin: 0 auto;
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			padding: 0 ${props => props.theme.gutter.large}px 0
				${props =>
					props.theme.gutter.large - props.theme.gutter.small}px;
		}
	}
`;

const BannerWrap = styled.div`
	&.hostjane-main__bannerWrap {
		background-color: ${props => props.backgroundColor};
		padding: 0;
		color: ${props => props.textColor};
		background-image: ${props => props.backgroundGradient || 'none'};

		a {
			color: #fff;
			padding: 0;
			border-bottom: 1px solid #fff;
			text-decoration: none;
			transition: border-color 200ms ease-out;

			&:hover {
				color: #fff;
				text-decoration: none;
				border-bottom: 1px solid #fff;
			}
		}

		${BannerContent} {
			mask-image: linear-gradient(
				to right,
				transparent,
				${props => props.backgroundColor} 1.5rem,
				${props => props.backgroundColor} 96%,
				transparent
			);
		}
		${BannerHideButton} {
			color: ${props => props.textColor};
		}

		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			position: relative;

			${BannerContent} {
				margin-right: 32px;
			}

			${BannerHideButton} {
				position: absolute;
				right: 10px;
			}
		}
	}
`;

export default function DismissableBanner({
	children = null,
	backgroundColor = 'transparent',
	textColor = 'inherit',
	backgroundGradient = 'none',
}) {
	const [hidden, setHidden] = useState(false);

	if (children === null || hidden) return null;

	return (
		<BannerWrap
			className="hostjane-main__bannerWrap"
			backgroundColor={backgroundColor}
			textColor={textColor}
			backgroundGradient={backgroundGradient}
		>
			<BannerContainer className="hostjane-main__bannerContainer">
				<BannerContent className="hostjane-main__bannerContent">
					{children}
				</BannerContent>
				<BannerHideButton
					className="hostjane-main__bannerHideButton"
					onClick={e => {
						e.preventDefault();
						setHidden(true);
					}}
				>
					<GenericIcon
						className="hostjane-main__genericIcon"
						title="Close banner"
					>
						<TimesIcon className="hostjane-main__timesIcon" />
					</GenericIcon>
				</BannerHideButton>
			</BannerContainer>
		</BannerWrap>
	);
}
