import React from 'react';
import Developers from './dev.jpg';
import Hosting from './hosting.jpg';
import Business from './business.jpg';
import Tutors from './tutors.jpg';
import Translators from './translators.jpg';
import Artists from './artists.jpg';
import { ReactComponent as RightArrow } from './right-arrow.svg';
import { ReactComponent as GoogleBtn } from '../../../src/svgs/icons/google.svg';
import { ReactComponent as EmailBtn } from '../../../src/svgs/icons/email.svg';
import { ReactComponent as TwitterBtn } from '../../../src/svgs/icons/twitter.svg';

// Developers images
import developersOne from './first-pool/1.jpeg';
import developersTwo from './first-pool/2.jpeg';
import developersThree from './first-pool/3.jpeg';
import developersFour from './first-pool/4.jpeg';
import developersFive from './first-pool/5.jpeg';
import developersSix from './first-pool/6.jpeg';
import developersSeven from './first-pool/7.jpeg';
import developersEight from './first-pool/8.jpeg';
import developersNine from './first-pool/9.jpeg';
import developersTen from './first-pool/10.jpeg';

// Business images
import BusinessOne from './second-pool/1.jpeg';
import BusinessTwo from './second-pool/2.jpeg';
import BusinessThree from './second-pool/3.jpeg';
import BusinessFour from './second-pool/4.jpeg';
import BusinessFive from './second-pool/5.jpeg';
import BusinessSix from './second-pool/6.jpeg';
import BusinessSeven from './second-pool/7.jpeg';
import BusinessEight from './second-pool/8.jpeg';
import BusinessNine from './second-pool/9.jpeg';
import BusinessTen from './second-pool/10.jpeg';

// Tutors images
import TutorsOne from './third-pool/1.jpeg';
import TutorsTwo from './third-pool/2.jpeg';
import TutorsThree from './third-pool/3.jpeg';
import TutorsFour from './third-pool/4.jpeg';
import TutorsFive from './third-pool/5.jpeg';
import TutorsSix from './third-pool/6.jpeg';
import TutorsSeven from './third-pool/7.jpeg';
import TutorsEight from './third-pool/8.jpeg';
import TutorsNine from './third-pool/9.jpeg';
import TutorsTen from './third-pool/10.jpeg';

// Writing images
import WritingOne from './fourth-pool/1.jpeg';
import WritingTwo from './fourth-pool/2.jpeg';
import WritingThree from './fourth-pool/3.jpeg';
import WritingFour from './fourth-pool/4.jpeg';
import WritingFive from './fourth-pool/5.jpeg';
import WritingSix from './fourth-pool/6.jpeg';
import WritingSeven from './fourth-pool/7.jpeg';
import WritingEight from './fourth-pool/8.jpeg';
import WritingNine from './fourth-pool/9.jpeg';
import WritingTen from './fourth-pool/10.jpeg';

// Writing images
import ArtistOne from './fifth-pool/1.jpeg';
import ArtistTwo from './fifth-pool/2.jpeg';
import ArtistThree from './fifth-pool/3.jpeg';
import ArtistFour from './fifth-pool/4.jpeg';
import ArtistFive from './fifth-pool/5.jpeg';
import ArtistSix from './fifth-pool/6.jpeg';
import ArtistSeven from './fifth-pool/7.jpeg';
import ArtistEight from './fifth-pool/8.jpeg';
import ArtistNine from './fifth-pool/9.jpeg';
import ArtistTen from './fifth-pool/10.jpeg';

const everydayFindsData = {
	title: 'The <span>small payments</span> platform',
	subtitle: 'Accept cash for your skill or save on hiring people and hosting your site',

	rightArrow: <RightArrow />,
	authButtons: [
		{
			text: 'Sign up with email',
			link: 'https://www.hostjane.com/marketplace/register',
			icon: <EmailBtn />,
		},
		{
			text: 'Sign up with Google',
			link: 'https://www.hostjane.com/marketplace/register',
			icon: <GoogleBtn />,
		},
		{
			text: 'Sign up with X',
			link: 'https://www.hostjane.com/marketplace/register',
			icon: <TwitterBtn />,
		},
	],
	shoppingItems: [
		{
			link: 'https://cloud.hostjane.com',
			title: 'Hosting',
			img: Hosting,
		},
		{
			link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
			title: 'Dev + IT',
			images: [
				developersOne,
				developersTwo,
				developersThree,
				developersFour,
				developersFive,
				developersSix,
				developersSeven,
				developersEight,
				developersNine,
				developersTen,
			],
		},
		{
			link: 'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors',
			title: 'Online Lessons',
			images: [
				TutorsOne,
				TutorsTwo,
				TutorsThree,
				TutorsFour,
				TutorsFive,
				TutorsSix,
				TutorsSeven,
				TutorsEight,
				TutorsNine,
				TutorsTen,
			],
		},
		{
			link: 'https://www.hostjane.com/marketplace/categories/business-admin-support',
			title: 'Admin + Sales',
			images: [
				BusinessOne,
				BusinessTwo,
				BusinessThree,
				BusinessFour,
				BusinessFive,
				BusinessSix,
				BusinessSeven,
				BusinessEight,
				BusinessNine,
				BusinessTen,
			],
		},
		{
			link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/',
			title: 'Writing + Transl.',
			images: [
				WritingOne,
				WritingTwo,
				WritingThree,
				WritingFour,
				WritingFive,
				WritingSix,
				WritingSeven,
				WritingEight,
				WritingNine,
				WritingTen,
			],
		},
		{
			link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/',
			title: 'Design + Video',
			images: [
				ArtistOne,
				ArtistTwo,
				ArtistThree,
				ArtistFour,
				ArtistFive,
				ArtistSix,
				ArtistSeven,
				ArtistEight,
				ArtistNine,
				ArtistTen,
			],
		},
	],
};

export default everydayFindsData;
