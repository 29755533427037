const sectionIds = {
	Hosting: 'hero',
	Freelancers: 'hostjane-sellers',
	Workspace: 'hostjane-slider',
	'How it works': 'how-it-works',
	Mission: 'what-is-hostjane',
	Creatives: 'service-slider2',
	Innovation: 'features',
};

export default sectionIds;
