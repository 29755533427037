import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Element } from 'react-scroll';
import { Heading, Wrapper } from '../common';
import SiteContainer from '../../SiteContainer';
import { StyledList } from '../../StyledContents';

const CardsWrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	align-content: center;
	justify-content: center;
	@media screen and (min-width: 1350px) {
		max-width: 1200px;
	}
`;

const CardContainer = styled.div`
	margin: 10px;
	width: 275px;
	flex: 0 1 275px;
	border-top: 5px solid #232f3e;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.04);
	background-color: #fff;
	z-index: 1;

	.hostjane-explore-card__megalink {
		color: #333;
		display: flex;
		flex-flow: column nowrap;
		height: 100%;
		text-decoration: none;
		&:hover {
			text-decoration: none;

			.hostjane-explore-card__anchor {
				text-decoration: underline;
				color: #e47911;
			}
		}
	}
	.hostjane-explore-card__body {
		padding: 15px 15px 0 15px;
		flex: 1 0 auto;
	}
	.hostjane-explore-card__icon {
		height: 60px;
		width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 60px;
			height: auto;
		}
	}
	.hostjane-explore-card__title {
		margin: 5px 0 15px;
		color: #0052cc;
		line-height: 1.5;
		font-weight: bold;
		font-size: 18px;
	}
	.hostjane-explore-card__description {
		margin: 0;
		padding: 0 0 15px 0;
		line-height: 1.6;
		font-size: 18px;
	}
	.hostjane-explore-card__list {
		font-size: 17px;
		margin-bottom: 15px;
		.hostjane-styledlist__icon {
			height: ${14 * 1.6}px;
		}
	}
	.hostjane-explore-card__link {
		padding: 0;
		border-top: 1px solid #e6e7e8;
		flex: 0 0 53px;
	}
	.hostjane-explore-card__anchor {
		padding: 0 0 0 15px;
		margin: 0;
		font-size: 16px;
		height: 53px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #0052cc;
		font-weight: 600;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			text-decoration: underline;
			color: #e47911;
		}
	}
`;
function Card({ data }) {
	const inner = (
		<>
			<div className="hostjane-explore-card__body">
				<div className="hostjane-explore-card__icon">
					<data.icon />
				</div>
				<h3 className="hostjane-explore-card__title">{data.title}</h3>
				<p className="hostjane-explore-card__description">
					{data.description}
				</p>
				<StyledList
					items={data.list}
					className="hostjane-explore-card__list"
				/>
			</div>
			<div className="hostjane-explore-card__link">
				<p className="hostjane-explore-card__anchor">
					{data.linkLabel}
				</p>
			</div>
		</>
	);
	return (
		<CardContainer className="hostjane-explore-card">
			{data.internal ? (
				<Link
					to={data.link}
					className="hostjane-explore-card__megalink"
				>
					{inner}
				</Link>
			) : (
				<a href={data.link} className="hostjane-explore-card__megalink">
					{inner}
				</a>
			)}
		</CardContainer>
	);
}

export default function Explore({ data, id }) {
	return (
		<Element name={id}>
			<Wrapper id={id}>
				<SiteContainer>
					<Heading>{data.title}</Heading>
					<CardsWrapper>
						{data.items.map((item, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<Card data={item} key={index} />
						))}
					</CardsWrapper>
				</SiteContainer>

				<div className="background-mask">
					<div className="background-mask__grid">
						<div className="background-mask__grid__item" />
						<div className="background-mask__grid__item" />
						<div className="background-mask__grid__item" />
						<div className="background-mask__grid__item" />
					</div>
				</div>
			</Wrapper>
		</Element>
	);
}
