import React from 'react';
import styled from 'styled-components';

import { Element } from 'react-scroll';
import SiteContainer from '../../SiteContainer';

import { Heading, Wrapper } from '../common';
import { HIWItem } from '../../StyledContents';

const HowItWorksContainer = styled(Wrapper)`
	background-color: #f4f8ff;
	padding-bottom: 0;

	${SiteContainer} {
		padding: 0;
	}

	${Heading} {
		font-weight: 800;
		color: #000;
		padding-left: 20px;
		padding-right: 20px;
	}

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		padding-bottom: 0;
	}

	@media screen and (min-width: 1024px) {
		padding-bottom: 40px;

		${SiteContainer} {
			padding: 0 10px;
		}
	}
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		${SiteContainer} {
			padding: 0 20px;
		}
	}
`;

const ItemsContainer = styled.div`
	display: flex;
	flex-flow: row wrap;

	@media screen and (min-width: 1024px) {
		justify-content: center;
	}
`;

export default function HowItWorks({ data, id }) {
	return (
		<Element name={id}>
			<HowItWorksContainer id={id}>
				<SiteContainer>
					<Heading>{data.heading}</Heading>
					<ItemsContainer>
						{data.items.map(({ description, ...item }, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<HIWItem {...item} index={index} key={index}>
								{description}
							</HIWItem>
						))}
					</ItemsContainer>
				</SiteContainer>
			</HowItWorksContainer>
		</Element>
	);
}
