import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Link } from 'gatsby';
import { Wrapper } from '../common';
import SiteContainer from '../../SiteContainer';
import Slider, { SingleSlideContainer, collapsedBp } from '../../Slider';

const HeadingContainer = styled.div`
	position: relative;
`;

const Heading = styled.h2`
	font-weight: 800;
	margin: 0;
	font-size: 35px;
	line-height: 45px;
	letter-spacing: 0.007em;
	color: rgb(34, 34, 34);
	padding: 2px 0 0 10px;
`;

const SubHeading = styled.h3`
	font-size: 22px;
	line-height: 28px;
	letter-spacing: normal;
	color: rgb(34, 34, 34);
	font-weight: 500;
	margin: 4px 0 22px 0;
	padding-left: 10px;

	u,
	a,
	button {
		appearance: none;
		cursor: pointer;
		padding: 0 0 2px 0;
		margin: 0;
		background: transparent;
		border: 0 none;
		color: rgb(34, 34, 34);
		text-decoration: none;
		border-bottom: 1px solid rgb(34, 34, 34);
		transition: border-color 200ms ease-out;
		&:hover {
			color: rgb(34, 34, 34);
			text-decoration: none;
			border-bottom: 1px solid rgb(34, 34, 34);
		}
	}
`;

const CardContainer = styled(SingleSlideContainer)`
	.hostjane-slider-card__link {
		display: block;
		border-radius: 16px;
		overflow: hidden;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
		cursor: pointer;
		overflow: hidden;
		color: rgb(72, 72, 72);
		text-decoration: none;
		height: 100%;
		&:hover {
			color: rgb(72, 72, 72);
		}

		position: relative;
	}

	.hostjane-slider-card__badge {
		position: absolute;
		left: 16px;
		top: 16px;
		background-color: rgb(255, 255, 255);
		color: rgb(34, 34, 34);
		font-size: 10px;
		line-height: 12px;
		display: inline-block;
		font-weight: 800;
		padding-bottom: 4px;
		padding-left: 6px;
		padding-right: 6px;
		padding-top: 4px;
		text-transform: uppercase;
		border-radius: 4px;
		z-index: 1;
	}

	.hostjane-slider-card__body {
		padding: 12px 16px 16px;
	}
	.hostjane-slider-card__title {
		overflow-wrap: break-word;
		font-size: 20px;
		font-weight: 800;
		line-height: 1.375em;
		text-overflow: ellipsis;
		max-height: 2.75em;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		margin: 0px;
		overflow: hidden;
	}
	.hostjane-slider-card__description {
		overflow-wrap: break-word;
		font-size: 17px;
		font-weight: 400;
		line-height: 1.4;
		color: rgb(72, 72, 72);
		text-overflow: ellipsis;
		max-height: 3.85714em;
		-webkit-line-clamp: 3;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		margin: 0px;
		overflow: hidden;
		margin-top: 2px;
		p {
			margin: 0 0 8px 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`;
function Card({ fm, body }) {
	return (
		<CardContainer>
			<a href={fm.link} className="hostjane-slider-card__link">
				{fm.isNew ? (
					<span className="hostjane-slider-card__badge">
						{fm.newBadge || 'NEW'}
					</span>
				) : null}
				<Img
					className="hostjane-slider-card__image"
					fluid={fm.image.childImageSharp.fluid}
					alt={fm.title}
					backgroundColor="#E3E3E3"
				/>
				<div className="hostjane-slider-card__body">
					<h4 className="hostjane-slider-card__title">{fm.title}</h4>
					<div className="hostjane-slider-card__description">
						<MDXRenderer>{body}</MDXRenderer>
					</div>
				</div>
			</a>
		</CardContainer>
	);
}

const darkCss = css`
	${Heading},
	${SubHeading} {
		color: #fff;
	}

	u,
	a,
	button {
		color: #f90;
		border-color: #f90;
	}

	a:hover,
	button:hover {
		color: #f90;
		border-color: #f90;
	}

	button.slick-arrow {
		color: #000;
	}

	${CardContainer} {
		.hostjane-slider-card__link {
			box-shadow: none;
			background-color: #1b1b1b;
			color: #fff;
		}
		.hostjane-slider-card__description {
			color: #fff;
		}
	}

	.hostjane-service-explore-button {
		color: rgb(255, 255, 255);
		border-color: rgb(255, 255, 255);
		&:hover {
			color: rgb(255, 255, 255);
			border-color: rgb(255, 255, 255);
			background: rgb(113, 113, 113);
		}
		&:active,
		&:focus,
		&:active:hover,
		&:focus:hover {
			color: rgb(255, 255, 255);
			border-color: rgb(255, 255, 255);
		}
	}
`;

const ServiceSliderWrapper = styled(Wrapper)`
	background-color: ${props => (props.mode === 'light' ? '#fff' : '#000')};

	.hostjane-service-explore-button {
		cursor: pointer;
		display: inline-block;
		position: relative;
		text-align: center;
		width: auto;
		font-size: 18px;
		line-height: 18px;
		font-weight: 600;
		padding-top: 7px;
		padding-bottom: 7px;
		padding-left: 15px;
		padding-right: 15px;
		color: #222;
		margin: 0px;
		text-decoration: none;
		border-radius: 8px;
		border-width: 1px;
		border-style: solid;
		outline: none;
		transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
			-webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
		border-color: #222;
		background: transparent;
		&:hover {
			color: #222;
			border-color: #222;
			background: #f2f2f2;
		}
		&:active,
		&:focus,
		&:active:hover,
		&:focus:hover {
			transform: scale(0.96);
			color: #222;
			border-color: #222;
			background: transparent;
		}
	}

	${props => (props.mode === 'light' ? '' : darkCss)}
`;

const ExploreFooterContainer = styled.div`
	padding: 32px 0 0 10px;
	@media ${props => props.theme.breakpoints.tabletQuery} {
		display: none;
	}
`;
const ExploreHeaderContainer = styled.div`
	display: none;
	position: absolute;
	right: 10px;
	top: 0;

	@media ${props => props.theme.breakpoints.tabletQuery} {
		display: block;
	}
`;

export default function ServiceSlider({
	slides,
	title,
	Description,
	mode = 'light',
	explore = {},
	id,
}) {
	let exploreChild = null;
	if (explore.url) {
		if (explore.internal) {
			exploreChild = (
				<Link
					to={explore.url}
					className="hostjane-service-explore-button"
				>
					{explore.label}
				</Link>
			);
		} else {
			exploreChild = (
				<a
					href={explore.url}
					className="hostjane-service-explore-button"
				>
					{explore.label}
				</a>
			);
		}
	}
	return (
		<ServiceSliderWrapper id={id} mode={mode}>
			<SiteContainer>
				<HeadingContainer>
					<Heading mode={mode}>{title}</Heading>
					<SubHeading>
						<Description />
					</SubHeading>
					{exploreChild ? (
						<ExploreHeaderContainer>
							{exploreChild}
						</ExploreHeaderContainer>
					) : null}
				</HeadingContainer>
				<Slider
					collapsed
					responsive={[
						{
							breakpoint: 50000,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 1,
								variableWidth: false,
							},
						},
						{
							breakpoint: 1800,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 1,
								variableWidth: false,
							},
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 1,
								variableWidth: false,
							},
						},
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
								variableWidth: false,
							},
						},
						{
							breakpoint: collapsedBp,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								variableWidth: true,
							},
						},
					]}
				>
					{slides.map(({ node }) => (
						<Card
							key={node.id}
							fm={node.frontmatter}
							body={node.body}
						/>
					))}
				</Slider>
				{exploreChild ? (
					<ExploreFooterContainer>
						{exploreChild}
					</ExploreFooterContainer>
				) : null}
			</SiteContainer>
		</ServiceSliderWrapper>
	);
}
